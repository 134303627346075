#large-nav {
    display: flex;
    z-index: 1;
    background-color: var(--purple-color);
    color: #fff;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: -50vw;
    left: -50vw;
    height: 0%;
    width: 0%;
    font-size: 3.5em;
    border-radius: 100%;
    transition: all .25s;
}

#large-nav img {
    cursor: pointer;
    margin: 5vh;
}

#large-nav a {
    display: flex;
    align-items: center;
    color: #fff;
    height: 100%;
    width: 100%;
    padding-left: 10vw;
}

#large-nav a:hover {
    background-color: #fff;
    color: var(--green-color);
    border: unset;
}

#header-nav {
    display: none;
}

#header-logo {
    cursor: pointer;
    height: 100%;
    margin-left: 10vw;
}

#header-logo img {
    height: 100%;
    width: auto;
}

#home {
    display: flex;
    position: relative;
    height: 100vh;
    padding: unset;
    margin: 0;
}

#home .content {
    align-items: center;
    align-self: center;
    padding-top: 70vh;
    padding-left: 10vw;
}

#home .content h1 {
    display: none;
}

#home .content-figure {
    position: absolute;
    display: flex;
    z-index: -1;
    align-items: center;
    height: 100%;
    top: 0;
    overflow: hidden;
    margin: 0;
}

#home .content-figure svg {
    height: auto;
    width: 250vw;
}

.process-step .content-figure {
    position: relative;
}

.process-step .content-step {
    display: flex;
    border-radius: 100%;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.process-step .desktop--content-step {
    display: none;
}

.process-step .content-step h3 {
    color: #fff;
    background-color: var(--purple-color);
    padding: 5px;
    margin: 0px auto;
    border-radius: 50%;
    border: 3px solid #fff;
} 

#about {
    position: relative;
    background-color: var(--purple-color);
    margin: 0px;
    color: #fff;
    padding-top: 5vh;
    padding-bottom: 5vh;
}

#about .section-break {
    position: absolute;
    top: 0;
    transform: translateY(-50%);
}

#about .section-break h2 {
    padding-top: 0px;
}

#about .section-break hr {
    display: none;
}

#contact form {
    display: flex;
    flex-direction: column;
}

#contact {
    background-image: url(assets/contact-planet.svg);
    background-repeat: no-repeat;
    background-size: 195%;
    background-position: 50% 300px;
    margin-bottom: 0px;
    padding-bottom: 200px;
}

#contact .border {
    border-color: var(--purple-color);
}

#contact .content-figure {
    display: none;
}
